import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import {
  Autocomplete,
  Button,
  IconButton,
  Menu,
  MenuItem,
  TablePagination,
  TextField,
} from "@mui/material";
import { AddCircleOutline, MoreVert } from "@mui/icons-material";
import UserModal from "./modal/user";
import { listUsersNomalService } from "./service/userService";
import * as Api from "../../../api/src/index";
import { listUsersAdminService } from "./service/userAdminService";
import UserAdminModal from "./modal/userAdmin";
import AddRoleSysAdmin from "./modal/AddRoleSysAdmin";
interface HeadCell {
  disablePadding: boolean;
  id: string;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: "firstName",
    numeric: false,
    disablePadding: false,
    label: "Name",
  },
  {
    id: "email",
    numeric: false,
    disablePadding: false,
    label: "email",
  },
  {
    id: "phoneNumber",
    numeric: false,
    disablePadding: false,
    label: "Phone number",
  },
  {
    id: "address",
    numeric: false,
    disablePadding: false,
    label: "Address",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
];

interface EnhancedTableProps {
  numSelected?: number;
  onRequestSort?: (event: React.MouseEvent<unknown>, property: string) => void;
  orderBy?: string;
  rowCount?: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort && onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell
          key={"action"}
          align={"center"}
          padding={"normal"}
          sortDirection={false}
        ></TableCell>
      </TableRow>
    </TableHead>
  );
}
const RoleList = [
  { label: "Institution admin", id: "OrgAdmin" },
  { label: "System admin", id: "SysAdmin" },
];
export default function UserSysAdmins() {
  const {
    handleShowMenuClick,
    handleMenuClose,
    AddNew,
    EditObj,
    getUsers,
    listData,
    SetUserRoleSysAdmin,
    anchorEl,
    query,
    userModalProp,
    onPageChange,
    onRowsPerPageChange,
    onKeyWordBlur,
    openMenu,
    closeModal,
    updateStatus,
    listCompanyData,
    addRoleSysAdminModalProp,
    closeRoleSysAdminModal,
    removeUserRoleAdmin,
  } = listUsersAdminService();
  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <Box
          display="grid"
          gap={2}
          style={{ padding: "10px 10px", display: "flex" }}
        >
          {/* <Box>
            <Autocomplete
              options={listCompanyData.map((o) => {
                return { label: o.name, id: o.id };
              })}
              value={
                listCompanyData
                  .filter((o) => o.id === query?.companyId)
                  .map((o) => {
                    return { label: o.name, id: o.id };
                  })[0]
              }
              size="small"
              sx={{ width: 300 }}
              onChange={(o, val) => {
                onChangeComapny(val?.id || 0);
              }}
              renderInput={(params) => (
                <TextField {...params} name="companyId" label="Company" />
              )}
            />
          </Box>
          <Box>
            <Autocomplete
              options={RoleList}
              value={
                RoleList.filter((o) => o.id === query?.userRole).map((o) => {
                  return { label: o.label, id: o.id };
                })[0]
              }
              size="small"
              sx={{ width: 300 }}
              onChange={(o, val) => {
                onChangeRole(val?.id || "");
              }}
              renderInput={(params) => (
                <TextField {...params} name="companyId" label="Role" />
              )}
            />
          </Box> */}
          <Box>
            <TextField
              size="small"
              onBlur={(e) => {
                onKeyWordBlur(e.target.value);
              }}
              label="Key word"
            />
          </Box>
          <Box flex={1} textAlign="right">
            <Button
              variant="outlined"
              style={{ marginRight: 10 }}
              onClick={() => {
                AddNew();
              }}
              startIcon={<AddCircleOutline />}
            >
              New
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                SetUserRoleSysAdmin();
              }}
              startIcon={<AddCircleOutline />}
            >
              Set User Admin
            </Button>
          </Box>
        </Box>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={"small"}
          >
            <EnhancedTableHead />
            <TableBody>
              {listData?.result?.items?.map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={row.id}
                    sx={{ cursor: "pointer" }}
                  >
                    <TableCell component="th" id={labelId} scope="row">
                      {`${row.firstName} ${row.lastName}`}
                    </TableCell>
                    <TableCell align="left">{row.email}</TableCell>
                    <TableCell align="left">{row.phoneNumber}</TableCell>
                    <TableCell align="left">{row.address}</TableCell>
                    <TableCell align="left">
                      {row.status === Api.RowStatus.Active
                        ? "Active"
                        : "InActive"}
                    </TableCell>
                    <TableCell align="center" width={30}>
                      <IconButton
                        id="basic-button"
                        aria-controls={openMenu ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={openMenu ? "true" : undefined}
                        onClick={(e) => {
                          handleShowMenuClick(e, row);
                        }}
                      >
                        <MoreVert />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={openMenu}
            onClose={handleMenuClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              onClick={() => {
                EditObj();
              }}
            >
              Edit
            </MenuItem>
            <MenuItem
              onClick={() => {
                updateStatus(Api.RowStatus.Delete);
              }}
            >
              Delete
            </MenuItem>
            <MenuItem
              onClick={() => {
                removeUserRoleAdmin();
              }}
            >
              Remove Role Admin
            </MenuItem>
          </Menu>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={listData?.result?.total || 0}
          rowsPerPage={query?.limit || 0}
          page={query?.page || 0}
          onPageChange={onPageChange}
          onRowsPerPageChange={(e) => {
            onRowsPerPageChange(e);
          }}
        />
      </Paper>
      {userModalProp.formStatus && (
        <UserAdminModal
          companys={listCompanyData}
          userItem={userModalProp.userItem}
          formStatus={userModalProp.formStatus}
          onClose={(action) => {
            if (action === "Save" && query) getUsers(query);
            closeModal();
          }}
        ></UserAdminModal>
      )}
      {addRoleSysAdminModalProp.show && (
        <AddRoleSysAdmin
          show={addRoleSysAdminModalProp.show}
          onClose={() => {
            closeRoleSysAdminModal();
          }}
        ></AddRoleSysAdmin>
      )}
    </Box>
  );
}
