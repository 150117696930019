/* tslint:disable */
/* eslint-disable */
/**
 * API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AccessToken
 */
export interface AccessToken {
    /**
     * 
     * @type {string}
     * @memberof AccessToken
     */
    'access_token'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccessToken
     */
    'refresh_token'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccessToken
     */
    'token_type'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AccessToken
     */
    'expires_in'?: number;
    /**
     * 
     * @type {UserInfo}
     * @memberof AccessToken
     */
    'user_info'?: UserInfo;
}
/**
 * 
 * @export
 * @interface AccessTokenOperationResult
 */
export interface AccessTokenOperationResult {
    /**
     * 
     * @type {AccessToken}
     * @memberof AccessTokenOperationResult
     */
    'result'?: AccessToken;
    /**
     * 
     * @type {boolean}
     * @memberof AccessTokenOperationResult
     */
    'isSuccess'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AccessTokenOperationResult
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AccessTokenOperationResult
     */
    'isException'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccessTokenOperationResult
     */
    'isNotFound'?: boolean;
}
/**
 * 
 * @export
 * @interface AddRoleSysAdminCommand
 */
export interface AddRoleSysAdminCommand {
    /**
     * 
     * @type {AddRoleSysAdminParam}
     * @memberof AddRoleSysAdminCommand
     */
    'param'?: AddRoleSysAdminParam;
}
/**
 * 
 * @export
 * @interface AddRoleSysAdminParam
 */
export interface AddRoleSysAdminParam {
    /**
     * 
     * @type {Array<number>}
     * @memberof AddRoleSysAdminParam
     */
    'ids'?: Array<number> | null;
}
/**
 * 
 * @export
 * @interface BooleanOperationResult
 */
export interface BooleanOperationResult {
    /**
     * 
     * @type {boolean}
     * @memberof BooleanOperationResult
     */
    'result'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BooleanOperationResult
     */
    'isSuccess'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BooleanOperationResult
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof BooleanOperationResult
     */
    'isException'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BooleanOperationResult
     */
    'isNotFound'?: boolean;
}
/**
 * 
 * @export
 * @interface ChangePasswordCommand
 */
export interface ChangePasswordCommand {
    /**
     * 
     * @type {string}
     * @memberof ChangePasswordCommand
     */
    'userName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChangePasswordCommand
     */
    'currentPassword'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChangePasswordCommand
     */
    'password'?: string | null;
}
/**
 * 
 * @export
 * @interface Company
 */
export interface Company {
    /**
     * 
     * @type {number}
     * @memberof Company
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'createdDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'modifiedDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'modifiedBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Company
     */
    'licenseCompanyId'?: number;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'phoneNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'address'?: string | null;
    /**
     * 
     * @type {RowStatus}
     * @memberof Company
     */
    'status'?: RowStatus;
    /**
     * 
     * @type {Array<User>}
     * @memberof Company
     */
    'users'?: Array<User> | null;
    /**
     * 
     * @type {number}
     * @memberof Company
     */
    'countryId'?: number | null;
    /**
     * 
     * @type {Country}
     * @memberof Company
     */
    'country'?: Country;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'passwordFileXtract'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'licenseKey'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'createFromPartner'?: string | null;
}


/**
 * 
 * @export
 * @interface CompanyCreateCommand
 */
export interface CompanyCreateCommand {
    /**
     * 
     * @type {CompanyCreateParam}
     * @memberof CompanyCreateCommand
     */
    'param'?: CompanyCreateParam;
}
/**
 * 
 * @export
 * @interface CompanyCreateCommandResult
 */
export interface CompanyCreateCommandResult {
    /**
     * 
     * @type {Company}
     * @memberof CompanyCreateCommandResult
     */
    'company'?: Company;
}
/**
 * 
 * @export
 * @interface CompanyCreateCommandResultOperationResult
 */
export interface CompanyCreateCommandResultOperationResult {
    /**
     * 
     * @type {CompanyCreateCommandResult}
     * @memberof CompanyCreateCommandResultOperationResult
     */
    'result'?: CompanyCreateCommandResult;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyCreateCommandResultOperationResult
     */
    'isSuccess'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CompanyCreateCommandResultOperationResult
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyCreateCommandResultOperationResult
     */
    'isException'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyCreateCommandResultOperationResult
     */
    'isNotFound'?: boolean;
}
/**
 * 
 * @export
 * @interface CompanyCreateParam
 */
export interface CompanyCreateParam {
    /**
     * 
     * @type {string}
     * @memberof CompanyCreateParam
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyCreateParam
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyCreateParam
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyCreateParam
     */
    'phoneNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyCreateParam
     */
    'address'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyCreateParam
     */
    'countryId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyCreateParam
     */
    'createFromPartner'?: string | null;
    /**
     * 
     * @type {RowStatus}
     * @memberof CompanyCreateParam
     */
    'status'?: RowStatus;
}


/**
 * 
 * @export
 * @interface CompanyEditCommand
 */
export interface CompanyEditCommand {
    /**
     * 
     * @type {CompanyEditParam}
     * @memberof CompanyEditCommand
     */
    'param'?: CompanyEditParam;
}
/**
 * 
 * @export
 * @interface CompanyEditCommandResult
 */
export interface CompanyEditCommandResult {
    /**
     * 
     * @type {Company}
     * @memberof CompanyEditCommandResult
     */
    'company'?: Company;
}
/**
 * 
 * @export
 * @interface CompanyEditCommandResultOperationResult
 */
export interface CompanyEditCommandResultOperationResult {
    /**
     * 
     * @type {CompanyEditCommandResult}
     * @memberof CompanyEditCommandResultOperationResult
     */
    'result'?: CompanyEditCommandResult;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyEditCommandResultOperationResult
     */
    'isSuccess'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CompanyEditCommandResultOperationResult
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyEditCommandResultOperationResult
     */
    'isException'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyEditCommandResultOperationResult
     */
    'isNotFound'?: boolean;
}
/**
 * 
 * @export
 * @interface CompanyEditParam
 */
export interface CompanyEditParam {
    /**
     * 
     * @type {number}
     * @memberof CompanyEditParam
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CompanyEditParam
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyEditParam
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyEditParam
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyEditParam
     */
    'phoneNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyEditParam
     */
    'address'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyEditParam
     */
    'countryId'?: number | null;
    /**
     * 
     * @type {RowStatus}
     * @memberof CompanyEditParam
     */
    'status'?: RowStatus;
}


/**
 * 
 * @export
 * @interface CompanyIPagedList
 */
export interface CompanyIPagedList {
    /**
     * 
     * @type {Array<Company>}
     * @memberof CompanyIPagedList
     */
    'items'?: Array<Company> | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyIPagedList
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyIPagedList
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyIPagedList
     */
    'total'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyIPagedList
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyIPagedList
     */
    'hasPrevious'?: boolean;
}
/**
 * 
 * @export
 * @interface CompanyIPagedListOperationResult
 */
export interface CompanyIPagedListOperationResult {
    /**
     * 
     * @type {CompanyIPagedList}
     * @memberof CompanyIPagedListOperationResult
     */
    'result'?: CompanyIPagedList;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyIPagedListOperationResult
     */
    'isSuccess'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CompanyIPagedListOperationResult
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyIPagedListOperationResult
     */
    'isException'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyIPagedListOperationResult
     */
    'isNotFound'?: boolean;
}
/**
 * 
 * @export
 * @interface CompanyRegister
 */
export interface CompanyRegister {
    /**
     * 
     * @type {number}
     * @memberof CompanyRegister
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CompanyRegister
     */
    'createdDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyRegister
     */
    'modifiedDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyRegister
     */
    'modifiedBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyRegister
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyRegister
     */
    'licenseCompanyId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CompanyRegister
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyRegister
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyRegister
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyRegister
     */
    'phoneNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyRegister
     */
    'address'?: string | null;
    /**
     * 
     * @type {RowStatus}
     * @memberof CompanyRegister
     */
    'status'?: RowStatus;
    /**
     * 
     * @type {string}
     * @memberof CompanyRegister
     */
    'licenseKey'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyRegister
     */
    'createFromPartner'?: string | null;
}


/**
 * 
 * @export
 * @interface CompanyRegisterCommand
 */
export interface CompanyRegisterCommand {
    /**
     * 
     * @type {RegisterCompanyParam}
     * @memberof CompanyRegisterCommand
     */
    'param'?: RegisterCompanyParam;
}
/**
 * 
 * @export
 * @interface CompanyRegisterCommandResult
 */
export interface CompanyRegisterCommandResult {
    /**
     * 
     * @type {CompanyRegister}
     * @memberof CompanyRegisterCommandResult
     */
    'company'?: CompanyRegister;
}
/**
 * 
 * @export
 * @interface CompanyRegisterCommandResultOperationResult
 */
export interface CompanyRegisterCommandResultOperationResult {
    /**
     * 
     * @type {CompanyRegisterCommandResult}
     * @memberof CompanyRegisterCommandResultOperationResult
     */
    'result'?: CompanyRegisterCommandResult;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyRegisterCommandResultOperationResult
     */
    'isSuccess'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CompanyRegisterCommandResultOperationResult
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyRegisterCommandResultOperationResult
     */
    'isException'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyRegisterCommandResultOperationResult
     */
    'isNotFound'?: boolean;
}
/**
 * 
 * @export
 * @interface Country
 */
export interface Country {
    /**
     * 
     * @type {number}
     * @memberof Country
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Country
     */
    'createdDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Country
     */
    'modifiedDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Country
     */
    'modifiedBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Country
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Country
     */
    'iso'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Country
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Country
     */
    'niceName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Country
     */
    'iso3'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Country
     */
    'numCode'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Country
     */
    'phoneCode'?: number;
}
/**
 * 
 * @export
 * @interface CountryListOperationResult
 */
export interface CountryListOperationResult {
    /**
     * 
     * @type {Array<Country>}
     * @memberof CountryListOperationResult
     */
    'result'?: Array<Country> | null;
    /**
     * 
     * @type {boolean}
     * @memberof CountryListOperationResult
     */
    'isSuccess'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CountryListOperationResult
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CountryListOperationResult
     */
    'isException'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CountryListOperationResult
     */
    'isNotFound'?: boolean;
}
/**
 * 
 * @export
 * @interface EnableTwoFactorModel
 */
export interface EnableTwoFactorModel {
    /**
     * 
     * @type {string}
     * @memberof EnableTwoFactorModel
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnableTwoFactorModel
     */
    'secretKey'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnableTwoFactorModel
     */
    'authenticatorUri'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnableTwoFactorModel
     */
    'code'?: string | null;
}
/**
 * 
 * @export
 * @interface GenerateTwoFactorInfoResult
 */
export interface GenerateTwoFactorInfoResult {
    /**
     * 
     * @type {string}
     * @memberof GenerateTwoFactorInfoResult
     */
    'secretKey'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GenerateTwoFactorInfoResult
     */
    'qrCodeUrl'?: string | null;
}
/**
 * 
 * @export
 * @interface GenerateTwoFactorInfoResultOperationResult
 */
export interface GenerateTwoFactorInfoResultOperationResult {
    /**
     * 
     * @type {GenerateTwoFactorInfoResult}
     * @memberof GenerateTwoFactorInfoResultOperationResult
     */
    'result'?: GenerateTwoFactorInfoResult;
    /**
     * 
     * @type {boolean}
     * @memberof GenerateTwoFactorInfoResultOperationResult
     */
    'isSuccess'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GenerateTwoFactorInfoResultOperationResult
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GenerateTwoFactorInfoResultOperationResult
     */
    'isException'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GenerateTwoFactorInfoResultOperationResult
     */
    'isNotFound'?: boolean;
}
/**
 * 
 * @export
 * @interface GenerateUserTokenQuery
 */
export interface GenerateUserTokenQuery {
    /**
     * 
     * @type {string}
     * @memberof GenerateUserTokenQuery
     */
    'userName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GenerateUserTokenQuery
     */
    'passWord'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GenerateUserTokenQuery
     */
    'appCode'?: string | null;
}
/**
 * 
 * @export
 * @interface GetTagUserCommand
 */
export interface GetTagUserCommand {
    /**
     * 
     * @type {number}
     * @memberof GetTagUserCommand
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface GetUserByCompanyParam
 */
export interface GetUserByCompanyParam {
    /**
     * 
     * @type {number}
     * @memberof GetUserByCompanyParam
     */
    'companyId'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetUserByCompanyParam
     */
    'keyWord'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetUserByCompanyParam
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetUserByCompanyParam
     */
    'limit'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetUserByCompanyParam
     */
    'userRole'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface GetUsersQuery
 */
export interface GetUsersQuery {
    /**
     * 
     * @type {GetUserByCompanyParam}
     * @memberof GetUsersQuery
     */
    'param'?: GetUserByCompanyParam;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ImportType = {
    Ignore: 'Ignore',
    Replicate: 'Replicate',
    Replace: 'Replace'
} as const;

export type ImportType = typeof ImportType[keyof typeof ImportType];


/**
 * 
 * @export
 * @interface ImportUserApprovalStampTemplateParam
 */
export interface ImportUserApprovalStampTemplateParam {
    /**
     * 
     * @type {string}
     * @memberof ImportUserApprovalStampTemplateParam
     */
    'data'?: string | null;
    /**
     * 
     * @type {ImportType}
     * @memberof ImportUserApprovalStampTemplateParam
     */
    'importType'?: ImportType;
}


/**
 * 
 * @export
 * @interface LicenseConfig
 */
export interface LicenseConfig {
    /**
     * 
     * @type {number}
     * @memberof LicenseConfig
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof LicenseConfig
     */
    'companyId'?: number;
    /**
     * 
     * @type {string}
     * @memberof LicenseConfig
     */
    'moduleKey'?: string | null;
}
/**
 * 
 * @export
 * @interface LicenseReponseResult
 */
export interface LicenseReponseResult {
    /**
     * 
     * @type {string}
     * @memberof LicenseReponseResult
     */
    'mode'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof LicenseReponseResult
     */
    'update'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LicenseReponseResult
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LicenseReponseResult
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LicenseReponseResult
     */
    'company_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LicenseReponseResult
     */
    'passwordFileXtract'?: string | null;
    /**
     * 
     * @type {Array<LicenseConfig>}
     * @memberof LicenseReponseResult
     */
    'modules'?: Array<LicenseConfig> | null;
}
/**
 * 
 * @export
 * @interface RefreshUserTokenCommand
 */
export interface RefreshUserTokenCommand {
    /**
     * 
     * @type {string}
     * @memberof RefreshUserTokenCommand
     */
    'refreshToken'?: string;
}
/**
 * 
 * @export
 * @interface RegisterCompanyFromLicenseAppParam
 */
export interface RegisterCompanyFromLicenseAppParam {
    /**
     * 
     * @type {TblInfo}
     * @memberof RegisterCompanyFromLicenseAppParam
     */
    'tblInfo'?: TblInfo;
    /**
     * 
     * @type {boolean}
     * @memberof RegisterCompanyFromLicenseAppParam
     */
    'sendMail'?: boolean;
}
/**
 * 
 * @export
 * @interface RegisterCompanyParam
 */
export interface RegisterCompanyParam {
    /**
     * 
     * @type {CompanyCreateParam}
     * @memberof RegisterCompanyParam
     */
    'company'?: CompanyCreateParam;
    /**
     * 
     * @type {UserAdminCreateParam}
     * @memberof RegisterCompanyParam
     */
    'user'?: UserAdminCreateParam;
    /**
     * 
     * @type {boolean}
     * @memberof RegisterCompanyParam
     */
    'sendMail'?: boolean;
}
/**
 * 
 * @export
 * @interface RemoveRoleSysAdminCommand
 */
export interface RemoveRoleSysAdminCommand {
    /**
     * 
     * @type {RemoveRoleSysAdminParam}
     * @memberof RemoveRoleSysAdminCommand
     */
    'param'?: RemoveRoleSysAdminParam;
}
/**
 * 
 * @export
 * @interface RemoveRoleSysAdminParam
 */
export interface RemoveRoleSysAdminParam {
    /**
     * 
     * @type {Array<number>}
     * @memberof RemoveRoleSysAdminParam
     */
    'ids'?: Array<number> | null;
}
/**
 * 
 * @export
 * @interface RemoveUserRoleCommand
 */
export interface RemoveUserRoleCommand {
    /**
     * 
     * @type {RemoveUserUserRoleParam}
     * @memberof RemoveUserRoleCommand
     */
    'param'?: RemoveUserUserRoleParam;
}
/**
 * 
 * @export
 * @interface RemoveUserRoleCommandResult
 */
export interface RemoveUserRoleCommandResult {
    /**
     * 
     * @type {boolean}
     * @memberof RemoveUserRoleCommandResult
     */
    'success'?: boolean;
}
/**
 * 
 * @export
 * @interface RemoveUserRoleCommandResultOperationResult
 */
export interface RemoveUserRoleCommandResultOperationResult {
    /**
     * 
     * @type {RemoveUserRoleCommandResult}
     * @memberof RemoveUserRoleCommandResultOperationResult
     */
    'result'?: RemoveUserRoleCommandResult;
    /**
     * 
     * @type {boolean}
     * @memberof RemoveUserRoleCommandResultOperationResult
     */
    'isSuccess'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RemoveUserRoleCommandResultOperationResult
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RemoveUserRoleCommandResultOperationResult
     */
    'isException'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RemoveUserRoleCommandResultOperationResult
     */
    'isNotFound'?: boolean;
}
/**
 * 
 * @export
 * @interface RemoveUserUserRoleParam
 */
export interface RemoveUserUserRoleParam {
    /**
     * 
     * @type {number}
     * @memberof RemoveUserUserRoleParam
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof RemoveUserUserRoleParam
     */
    'role'?: string | null;
}
/**
 * 
 * @export
 * @interface Role
 */
export interface Role {
    /**
     * 
     * @type {number}
     * @memberof Role
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Role
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Role
     */
    'normalizedName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Role
     */
    'concurrencyStamp'?: string | null;
    /**
     * 
     * @type {RowStatus}
     * @memberof Role
     */
    'status'?: RowStatus;
    /**
     * 
     * @type {string}
     * @memberof Role
     */
    'displayName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Role
     */
    'createdDate'?: string;
    /**
     * 
     * @type {Array<RoleClaim>}
     * @memberof Role
     */
    'claims'?: Array<RoleClaim> | null;
    /**
     * 
     * @type {Array<UserRole>}
     * @memberof Role
     */
    'users'?: Array<UserRole> | null;
    /**
     * 
     * @type {string}
     * @memberof Role
     */
    'modifiedDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Role
     */
    'modifiedBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Role
     */
    'createdBy'?: string | null;
}


/**
 * 
 * @export
 * @interface RoleClaim
 */
export interface RoleClaim {
    /**
     * 
     * @type {number}
     * @memberof RoleClaim
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof RoleClaim
     */
    'roleId'?: number;
    /**
     * 
     * @type {string}
     * @memberof RoleClaim
     */
    'claimType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RoleClaim
     */
    'claimValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RoleClaim
     */
    'createdClaim'?: string;
    /**
     * 
     * @type {Role}
     * @memberof RoleClaim
     */
    'role'?: Role;
    /**
     * 
     * @type {string}
     * @memberof RoleClaim
     */
    'createdDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof RoleClaim
     */
    'modifiedDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RoleClaim
     */
    'modifiedBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RoleClaim
     */
    'createdBy'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const RowStatus = {
    Active: 'Active',
    Inactive: 'Inactive',
    Delete: 'Delete'
} as const;

export type RowStatus = typeof RowStatus[keyof typeof RowStatus];


/**
 * 
 * @export
 * @interface StringOperationResult
 */
export interface StringOperationResult {
    /**
     * 
     * @type {string}
     * @memberof StringOperationResult
     */
    'result'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof StringOperationResult
     */
    'isSuccess'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof StringOperationResult
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof StringOperationResult
     */
    'isException'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StringOperationResult
     */
    'isNotFound'?: boolean;
}
/**
 * 
 * @export
 * @interface TblInfo
 */
export interface TblInfo {
    /**
     * 
     * @type {number}
     * @memberof TblInfo
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblInfo
     */
    'country'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblInfo
     */
    'clientType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblInfo
     */
    'clientName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblInfo
     */
    'address'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblInfo
     */
    'address2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblInfo
     */
    'zip'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblInfo
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblInfo
     */
    'state'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblInfo
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblInfo
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblInfo
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblInfo
     */
    'salutation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblInfo
     */
    'department'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblInfo
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblInfo
     */
    'phone2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblInfo
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblInfo
     */
    'adminEmail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblInfo
     */
    'paymentType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblInfo
     */
    'paymentNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblInfo
     */
    'licenseType'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblInfo
     */
    'keyGenerationMode'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblInfo
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblInfo
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblInfo
     */
    'licenseCreated'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblInfo
     */
    'created'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblInfo
     */
    'licenseNumber'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblInfo
     */
    'key'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblInfo
     */
    'createFromPartner'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblInfo
     */
    'status'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblInfo
     */
    'passwordFileXtract'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblInfo
     */
    'managerCompanyId'?: string | null;
}
/**
 * 
 * @export
 * @interface TwoStepLoginModel
 */
export interface TwoStepLoginModel {
    /**
     * 
     * @type {string}
     * @memberof TwoStepLoginModel
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TwoStepLoginModel
     */
    'code'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateCompanyFromLicenseAppParam
 */
export interface UpdateCompanyFromLicenseAppParam {
    /**
     * 
     * @type {TblInfo}
     * @memberof UpdateCompanyFromLicenseAppParam
     */
    'tblInfo'?: TblInfo;
}
/**
 * 
 * @export
 * @interface UpdateTagUserCommand
 */
export interface UpdateTagUserCommand {
    /**
     * 
     * @type {UpdateTagUserParam}
     * @memberof UpdateTagUserCommand
     */
    'param'?: UpdateTagUserParam;
}
/**
 * 
 * @export
 * @interface UpdateTagUserParam
 */
export interface UpdateTagUserParam {
    /**
     * 
     * @type {number}
     * @memberof UpdateTagUserParam
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateTagUserParam
     */
    'tagUser'?: string | null;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'userName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'normalizedUserName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'normalizedEmail'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'emailConfirmed'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'passwordHash'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'securityStamp'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'concurrencyStamp'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'phoneNumber'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'phoneNumberConfirmed'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'twoFactorEnabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'lockoutEnd'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'lockoutEnabled'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'accessFailedCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'twoFactorSecretKey'?: string | null;
    /**
     * 
     * @type {RowStatus}
     * @memberof User
     */
    'status'?: RowStatus;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'address'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'generatedCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'tagUser'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'countryId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'companyId'?: number | null;
    /**
     * 
     * @type {Country}
     * @memberof User
     */
    'country'?: Country;
    /**
     * 
     * @type {Company}
     * @memberof User
     */
    'company'?: Company;
    /**
     * 
     * @type {Array<UserApprovalStampTemplate>}
     * @memberof User
     */
    'userApprovalStampTemplates'?: Array<UserApprovalStampTemplate> | null;
    /**
     * 
     * @type {Array<UserRole>}
     * @memberof User
     */
    'userRoles'?: Array<UserRole> | null;
    /**
     * 
     * @type {Array<UserLogin>}
     * @memberof User
     */
    'logins'?: Array<UserLogin> | null;
    /**
     * 
     * @type {Array<UserClaim>}
     * @memberof User
     */
    'claims'?: Array<UserClaim> | null;
    /**
     * 
     * @type {Array<UserToken>}
     * @memberof User
     */
    'tokens'?: Array<UserToken> | null;
    /**
     * 
     * @type {Array<UserRefreshToken>}
     * @memberof User
     */
    'userRefreshTokens'?: Array<UserRefreshToken> | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'createdDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'modifiedDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'modifiedBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'createdBy'?: string | null;
}


/**
 * 
 * @export
 * @interface UserAdminCreateParam
 */
export interface UserAdminCreateParam {
    /**
     * 
     * @type {string}
     * @memberof UserAdminCreateParam
     */
    'userName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserAdminCreateParam
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserAdminCreateParam
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserAdminCreateParam
     */
    'phoneNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserAdminCreateParam
     */
    'address'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserAdminCreateParam
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserAdminCreateParam
     */
    'password'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UserAdminCreateParam
     */
    'companyId'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserAdminCreateParam
     */
    'twoFactorEnabled'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UserAdminCreateParam
     */
    'countryId'?: number | null;
}
/**
 * 
 * @export
 * @interface UserApprovalStampTemplate
 */
export interface UserApprovalStampTemplate {
    /**
     * 
     * @type {string}
     * @memberof UserApprovalStampTemplate
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserApprovalStampTemplate
     */
    'createdDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserApprovalStampTemplate
     */
    'modifiedDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserApprovalStampTemplate
     */
    'modifiedBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserApprovalStampTemplate
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UserApprovalStampTemplate
     */
    'userId'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserApprovalStampTemplate
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UserApprovalStampTemplate
     */
    'widthCm'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserApprovalStampTemplate
     */
    'approvalStampDesignRepx'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UserApprovalStampTemplate
     */
    'transparent'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UserApprovalStampTemplate
     */
    'addFrame'?: boolean;
}
/**
 * 
 * @export
 * @interface UserClaim
 */
export interface UserClaim {
    /**
     * 
     * @type {number}
     * @memberof UserClaim
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserClaim
     */
    'userId'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserClaim
     */
    'claimType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserClaim
     */
    'claimValue'?: string | null;
    /**
     * 
     * @type {User}
     * @memberof UserClaim
     */
    'user'?: User;
    /**
     * 
     * @type {string}
     * @memberof UserClaim
     */
    'createdDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserClaim
     */
    'modifiedDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserClaim
     */
    'modifiedBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserClaim
     */
    'createdBy'?: string | null;
}
/**
 * 
 * @export
 * @interface UserCreateCommandResult
 */
export interface UserCreateCommandResult {
    /**
     * 
     * @type {User}
     * @memberof UserCreateCommandResult
     */
    'user'?: User;
}
/**
 * 
 * @export
 * @interface UserCreateCommandResultOperationResult
 */
export interface UserCreateCommandResultOperationResult {
    /**
     * 
     * @type {UserCreateCommandResult}
     * @memberof UserCreateCommandResultOperationResult
     */
    'result'?: UserCreateCommandResult;
    /**
     * 
     * @type {boolean}
     * @memberof UserCreateCommandResultOperationResult
     */
    'isSuccess'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserCreateCommandResultOperationResult
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserCreateCommandResultOperationResult
     */
    'isException'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserCreateCommandResultOperationResult
     */
    'isNotFound'?: boolean;
}
/**
 * 
 * @export
 * @interface UserEditCommand
 */
export interface UserEditCommand {
    /**
     * 
     * @type {UserEditParam}
     * @memberof UserEditCommand
     */
    'param'?: UserEditParam;
}
/**
 * 
 * @export
 * @interface UserEditCommandResult
 */
export interface UserEditCommandResult {
    /**
     * 
     * @type {boolean}
     * @memberof UserEditCommandResult
     */
    'success'?: boolean;
}
/**
 * 
 * @export
 * @interface UserEditCommandResultOperationResult
 */
export interface UserEditCommandResultOperationResult {
    /**
     * 
     * @type {UserEditCommandResult}
     * @memberof UserEditCommandResultOperationResult
     */
    'result'?: UserEditCommandResult;
    /**
     * 
     * @type {boolean}
     * @memberof UserEditCommandResultOperationResult
     */
    'isSuccess'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserEditCommandResultOperationResult
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserEditCommandResultOperationResult
     */
    'isException'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserEditCommandResultOperationResult
     */
    'isNotFound'?: boolean;
}
/**
 * 
 * @export
 * @interface UserEditParam
 */
export interface UserEditParam {
    /**
     * 
     * @type {number}
     * @memberof UserEditParam
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserEditParam
     */
    'userName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserEditParam
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserEditParam
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserEditParam
     */
    'phoneNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserEditParam
     */
    'address'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserEditParam
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserEditParam
     */
    'passWord'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UserEditParam
     */
    'companyId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UserEditParam
     */
    'countryId'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserEditParam
     */
    'twoFactorEnabled'?: boolean;
    /**
     * 
     * @type {RowStatus}
     * @memberof UserEditParam
     */
    'status'?: RowStatus;
}


/**
 * 
 * @export
 * @interface UserIPagedList
 */
export interface UserIPagedList {
    /**
     * 
     * @type {Array<User>}
     * @memberof UserIPagedList
     */
    'items'?: Array<User> | null;
    /**
     * 
     * @type {number}
     * @memberof UserIPagedList
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserIPagedList
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserIPagedList
     */
    'total'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UserIPagedList
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserIPagedList
     */
    'hasPrevious'?: boolean;
}
/**
 * 
 * @export
 * @interface UserIPagedListOperationResult
 */
export interface UserIPagedListOperationResult {
    /**
     * 
     * @type {UserIPagedList}
     * @memberof UserIPagedListOperationResult
     */
    'result'?: UserIPagedList;
    /**
     * 
     * @type {boolean}
     * @memberof UserIPagedListOperationResult
     */
    'isSuccess'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserIPagedListOperationResult
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserIPagedListOperationResult
     */
    'isException'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserIPagedListOperationResult
     */
    'isNotFound'?: boolean;
}
/**
 * 
 * @export
 * @interface UserInfo
 */
export interface UserInfo {
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'concurrencyStamp'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'userName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'phoneNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'address'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UserInfo
     */
    'companyId'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserInfo
     */
    'licenseCompanyId'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'countryCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'licenseKey'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'twoFactorGuid'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserInfo
     */
    'twoFactorEnabled'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserInfo
     */
    'role'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface UserLogin
 */
export interface UserLogin {
    /**
     * 
     * @type {string}
     * @memberof UserLogin
     */
    'loginProvider'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserLogin
     */
    'providerKey'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserLogin
     */
    'providerDisplayName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UserLogin
     */
    'userId'?: number;
    /**
     * 
     * @type {User}
     * @memberof UserLogin
     */
    'user'?: User;
    /**
     * 
     * @type {string}
     * @memberof UserLogin
     */
    'loggedOn'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserLogin
     */
    'createdDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserLogin
     */
    'modifiedDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserLogin
     */
    'modifiedBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserLogin
     */
    'createdBy'?: string | null;
}
/**
 * 
 * @export
 * @interface UserNomalCreateCommand
 */
export interface UserNomalCreateCommand {
    /**
     * 
     * @type {UserNomalCreateParam}
     * @memberof UserNomalCreateCommand
     */
    'param'?: UserNomalCreateParam;
}
/**
 * 
 * @export
 * @interface UserNomalCreateParam
 */
export interface UserNomalCreateParam {
    /**
     * 
     * @type {string}
     * @memberof UserNomalCreateParam
     */
    'userName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserNomalCreateParam
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserNomalCreateParam
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserNomalCreateParam
     */
    'phoneNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserNomalCreateParam
     */
    'address'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserNomalCreateParam
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserNomalCreateParam
     */
    'password'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UserNomalCreateParam
     */
    'companyId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UserNomalCreateParam
     */
    'countryId'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserNomalCreateParam
     */
    'twoFactorEnabled'?: boolean;
    /**
     * 
     * @type {RowStatus}
     * @memberof UserNomalCreateParam
     */
    'status'?: RowStatus;
}


/**
 * 
 * @export
 * @interface UserRefreshToken
 */
export interface UserRefreshToken {
    /**
     * 
     * @type {string}
     * @memberof UserRefreshToken
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserRefreshToken
     */
    'createdDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserRefreshToken
     */
    'modifiedDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserRefreshToken
     */
    'modifiedBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserRefreshToken
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UserRefreshToken
     */
    'userId'?: number;
    /**
     * 
     * @type {User}
     * @memberof UserRefreshToken
     */
    'user'?: User;
    /**
     * 
     * @type {string}
     * @memberof UserRefreshToken
     */
    'createdAt'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserRefreshToken
     */
    'isValid'?: boolean;
}
/**
 * 
 * @export
 * @interface UserRole
 */
export interface UserRole {
    /**
     * 
     * @type {number}
     * @memberof UserRole
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserRole
     */
    'roleId'?: number;
    /**
     * 
     * @type {User}
     * @memberof UserRole
     */
    'user'?: User;
    /**
     * 
     * @type {Role}
     * @memberof UserRole
     */
    'role'?: Role;
    /**
     * 
     * @type {string}
     * @memberof UserRole
     */
    'createdUserRoleDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserRole
     */
    'createdDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserRole
     */
    'modifiedDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserRole
     */
    'modifiedBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserRole
     */
    'createdBy'?: string | null;
}
/**
 * 
 * @export
 * @interface UserSetRoleCommand
 */
export interface UserSetRoleCommand {
    /**
     * 
     * @type {UserSetRoleParam}
     * @memberof UserSetRoleCommand
     */
    'param'?: UserSetRoleParam;
}
/**
 * 
 * @export
 * @interface UserSetRoleCommandResult
 */
export interface UserSetRoleCommandResult {
    /**
     * 
     * @type {boolean}
     * @memberof UserSetRoleCommandResult
     */
    'success'?: boolean;
}
/**
 * 
 * @export
 * @interface UserSetRoleCommandResultOperationResult
 */
export interface UserSetRoleCommandResultOperationResult {
    /**
     * 
     * @type {UserSetRoleCommandResult}
     * @memberof UserSetRoleCommandResultOperationResult
     */
    'result'?: UserSetRoleCommandResult;
    /**
     * 
     * @type {boolean}
     * @memberof UserSetRoleCommandResultOperationResult
     */
    'isSuccess'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserSetRoleCommandResultOperationResult
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserSetRoleCommandResultOperationResult
     */
    'isException'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserSetRoleCommandResultOperationResult
     */
    'isNotFound'?: boolean;
}
/**
 * 
 * @export
 * @interface UserSetRoleParam
 */
export interface UserSetRoleParam {
    /**
     * 
     * @type {number}
     * @memberof UserSetRoleParam
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserSetRoleParam
     */
    'role'?: string | null;
}
/**
 * 
 * @export
 * @interface UserStatusResult
 */
export interface UserStatusResult {
    /**
     * 
     * @type {RowStatus}
     * @memberof UserStatusResult
     */
    'status'?: RowStatus;
    /**
     * 
     * @type {LicenseReponseResult}
     * @memberof UserStatusResult
     */
    'licenseData'?: LicenseReponseResult;
}


/**
 * 
 * @export
 * @interface UserStatusResultOperationResult
 */
export interface UserStatusResultOperationResult {
    /**
     * 
     * @type {UserStatusResult}
     * @memberof UserStatusResultOperationResult
     */
    'result'?: UserStatusResult;
    /**
     * 
     * @type {boolean}
     * @memberof UserStatusResultOperationResult
     */
    'isSuccess'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserStatusResultOperationResult
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserStatusResultOperationResult
     */
    'isException'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserStatusResultOperationResult
     */
    'isNotFound'?: boolean;
}
/**
 * 
 * @export
 * @interface UserSysAdminCreateCommand
 */
export interface UserSysAdminCreateCommand {
    /**
     * 
     * @type {UserSysAdminCreateParam}
     * @memberof UserSysAdminCreateCommand
     */
    'param'?: UserSysAdminCreateParam;
}
/**
 * 
 * @export
 * @interface UserSysAdminCreateParam
 */
export interface UserSysAdminCreateParam {
    /**
     * 
     * @type {string}
     * @memberof UserSysAdminCreateParam
     */
    'userName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserSysAdminCreateParam
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserSysAdminCreateParam
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserSysAdminCreateParam
     */
    'phoneNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserSysAdminCreateParam
     */
    'address'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserSysAdminCreateParam
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserSysAdminCreateParam
     */
    'password'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UserSysAdminCreateParam
     */
    'companyId'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserSysAdminCreateParam
     */
    'twoFactorEnabled'?: boolean;
    /**
     * 
     * @type {RowStatus}
     * @memberof UserSysAdminCreateParam
     */
    'status'?: RowStatus;
}


/**
 * 
 * @export
 * @interface UserToken
 */
export interface UserToken {
    /**
     * 
     * @type {number}
     * @memberof UserToken
     */
    'userId'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserToken
     */
    'loginProvider'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserToken
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserToken
     */
    'value'?: string | null;
    /**
     * 
     * @type {User}
     * @memberof UserToken
     */
    'user'?: User;
    /**
     * 
     * @type {string}
     * @memberof UserToken
     */
    'generatedTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserToken
     */
    'createdDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserToken
     */
    'modifiedDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserToken
     */
    'modifiedBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserToken
     */
    'createdBy'?: string | null;
}
/**
 * 
 * @export
 * @interface UserTokenRequestQuery
 */
export interface UserTokenRequestQuery {
    /**
     * 
     * @type {string}
     * @memberof UserTokenRequestQuery
     */
    'userPhoneNumber'?: string | null;
}

/**
 * CompanyApi - axios parameter creator
 * @export
 */
export const CompanyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CompanyCreateCommand} [companyCreateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompanyCreatePost: async (companyCreateCommand?: CompanyCreateCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Company/Create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(companyCreateCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CompanyEditCommand} [companyEditCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompanyEditPut: async (companyEditCommand?: CompanyEditCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Company/Edit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(companyEditCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [keyword] 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompanyGetAllCompanysGet: async (keyword?: string, page?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Company/GetAllCompanys`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompanyGetCompanyByLicenseKeyPost: async (key?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Company/GetCompanyByLicenseKey`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RegisterCompanyFromLicenseAppParam} [registerCompanyFromLicenseAppParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompanyRegisterCompanyFromLicenseAppPost: async (registerCompanyFromLicenseAppParam?: RegisterCompanyFromLicenseAppParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Company/RegisterCompanyFromLicenseApp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registerCompanyFromLicenseAppParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateCompanyFromLicenseAppParam} [updateCompanyFromLicenseAppParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompanyUpdateCompanyFromLicenseAppPost: async (updateCompanyFromLicenseAppParam?: UpdateCompanyFromLicenseAppParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Company/UpdateCompanyFromLicenseApp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCompanyFromLicenseAppParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompanyApi - functional programming interface
 * @export
 */
export const CompanyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CompanyApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CompanyCreateCommand} [companyCreateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCompanyCreatePost(companyCreateCommand?: CompanyCreateCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyCreateCommandResultOperationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCompanyCreatePost(companyCreateCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CompanyEditCommand} [companyEditCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCompanyEditPut(companyEditCommand?: CompanyEditCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyEditCommandResultOperationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCompanyEditPut(companyEditCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [keyword] 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCompanyGetAllCompanysGet(keyword?: string, page?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyIPagedListOperationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCompanyGetAllCompanysGet(keyword, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCompanyGetCompanyByLicenseKeyPost(key?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Company>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCompanyGetCompanyByLicenseKeyPost(key, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RegisterCompanyFromLicenseAppParam} [registerCompanyFromLicenseAppParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCompanyRegisterCompanyFromLicenseAppPost(registerCompanyFromLicenseAppParam?: RegisterCompanyFromLicenseAppParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyRegisterCommandResultOperationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCompanyRegisterCompanyFromLicenseAppPost(registerCompanyFromLicenseAppParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateCompanyFromLicenseAppParam} [updateCompanyFromLicenseAppParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCompanyUpdateCompanyFromLicenseAppPost(updateCompanyFromLicenseAppParam?: UpdateCompanyFromLicenseAppParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyRegisterCommandResultOperationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCompanyUpdateCompanyFromLicenseAppPost(updateCompanyFromLicenseAppParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CompanyApi - factory interface
 * @export
 */
export const CompanyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CompanyApiFp(configuration)
    return {
        /**
         * 
         * @param {CompanyCreateCommand} [companyCreateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompanyCreatePost(companyCreateCommand?: CompanyCreateCommand, options?: any): AxiosPromise<CompanyCreateCommandResultOperationResult> {
            return localVarFp.apiCompanyCreatePost(companyCreateCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CompanyEditCommand} [companyEditCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompanyEditPut(companyEditCommand?: CompanyEditCommand, options?: any): AxiosPromise<CompanyEditCommandResultOperationResult> {
            return localVarFp.apiCompanyEditPut(companyEditCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [keyword] 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompanyGetAllCompanysGet(keyword?: string, page?: number, limit?: number, options?: any): AxiosPromise<CompanyIPagedListOperationResult> {
            return localVarFp.apiCompanyGetAllCompanysGet(keyword, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompanyGetCompanyByLicenseKeyPost(key?: string, options?: any): AxiosPromise<Company> {
            return localVarFp.apiCompanyGetCompanyByLicenseKeyPost(key, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RegisterCompanyFromLicenseAppParam} [registerCompanyFromLicenseAppParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompanyRegisterCompanyFromLicenseAppPost(registerCompanyFromLicenseAppParam?: RegisterCompanyFromLicenseAppParam, options?: any): AxiosPromise<CompanyRegisterCommandResultOperationResult> {
            return localVarFp.apiCompanyRegisterCompanyFromLicenseAppPost(registerCompanyFromLicenseAppParam, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateCompanyFromLicenseAppParam} [updateCompanyFromLicenseAppParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompanyUpdateCompanyFromLicenseAppPost(updateCompanyFromLicenseAppParam?: UpdateCompanyFromLicenseAppParam, options?: any): AxiosPromise<CompanyRegisterCommandResultOperationResult> {
            return localVarFp.apiCompanyUpdateCompanyFromLicenseAppPost(updateCompanyFromLicenseAppParam, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CompanyApi - object-oriented interface
 * @export
 * @class CompanyApi
 * @extends {BaseAPI}
 */
export class CompanyApi extends BaseAPI {
    /**
     * 
     * @param {CompanyCreateCommand} [companyCreateCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public apiCompanyCreatePost(companyCreateCommand?: CompanyCreateCommand, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).apiCompanyCreatePost(companyCreateCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CompanyEditCommand} [companyEditCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public apiCompanyEditPut(companyEditCommand?: CompanyEditCommand, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).apiCompanyEditPut(companyEditCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [keyword] 
     * @param {number} [page] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public apiCompanyGetAllCompanysGet(keyword?: string, page?: number, limit?: number, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).apiCompanyGetAllCompanysGet(keyword, page, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [key] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public apiCompanyGetCompanyByLicenseKeyPost(key?: string, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).apiCompanyGetCompanyByLicenseKeyPost(key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RegisterCompanyFromLicenseAppParam} [registerCompanyFromLicenseAppParam] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public apiCompanyRegisterCompanyFromLicenseAppPost(registerCompanyFromLicenseAppParam?: RegisterCompanyFromLicenseAppParam, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).apiCompanyRegisterCompanyFromLicenseAppPost(registerCompanyFromLicenseAppParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateCompanyFromLicenseAppParam} [updateCompanyFromLicenseAppParam] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public apiCompanyUpdateCompanyFromLicenseAppPost(updateCompanyFromLicenseAppParam?: UpdateCompanyFromLicenseAppParam, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).apiCompanyUpdateCompanyFromLicenseAppPost(updateCompanyFromLicenseAppParam, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CountryApi - axios parameter creator
 * @export
 */
export const CountryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {object} [getAllCountry] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCountryGetAllCountrysGet: async (getAllCountry?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Country/GetAllCountrys`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (getAllCountry !== undefined) {
                localVarQueryParameter['getAllCountry'] = getAllCountry;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CountryApi - functional programming interface
 * @export
 */
export const CountryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CountryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {object} [getAllCountry] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCountryGetAllCountrysGet(getAllCountry?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CountryListOperationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCountryGetAllCountrysGet(getAllCountry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CountryApi - factory interface
 * @export
 */
export const CountryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CountryApiFp(configuration)
    return {
        /**
         * 
         * @param {object} [getAllCountry] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCountryGetAllCountrysGet(getAllCountry?: object, options?: any): AxiosPromise<CountryListOperationResult> {
            return localVarFp.apiCountryGetAllCountrysGet(getAllCountry, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CountryApi - object-oriented interface
 * @export
 * @class CountryApi
 * @extends {BaseAPI}
 */
export class CountryApi extends BaseAPI {
    /**
     * 
     * @param {object} [getAllCountry] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CountryApi
     */
    public apiCountryGetAllCountrysGet(getAllCountry?: object, options?: AxiosRequestConfig) {
        return CountryApiFp(this.configuration).apiCountryGetAllCountrysGet(getAllCountry, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TestApi - axios parameter creator
 * @export
 */
export const TestApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTestPingGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/test/ping`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TestApi - functional programming interface
 * @export
 */
export const TestApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TestApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTestPingGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTestPingGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TestApi - factory interface
 * @export
 */
export const TestApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TestApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTestPingGet(options?: any): AxiosPromise<string> {
            return localVarFp.apiTestPingGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TestApi - object-oriented interface
 * @export
 * @class TestApi
 * @extends {BaseAPI}
 */
export class TestApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public apiTestPingGet(options?: AxiosRequestConfig) {
        return TestApiFp(this.configuration).apiTestPingGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AddRoleSysAdminCommand} [addRoleSysAdminCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserAddRoleSysAdminPost: async (addRoleSysAdminCommand?: AddRoleSysAdminCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/AddRoleSysAdmin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addRoleSysAdminCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ChangePasswordCommand} [changePasswordCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserChangePasswordPost: async (changePasswordCommand?: ChangePasswordCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/ChangePassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changePasswordCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {object} [command] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserCheckUserStatusGet: async (command?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/CheckUserStatus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (command !== undefined) {
                localVarQueryParameter['command'] = command;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserSysAdminCreateCommand} [userSysAdminCreateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserCreateSysAdminPost: async (userSysAdminCreateCommand?: UserSysAdminCreateCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/CreateSysAdmin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userSysAdminCreateCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserEditCommand} [userEditCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserEditPut: async (userEditCommand?: UserEditCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/Edit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userEditCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EnableTwoFactorModel} [enableTwoFactorModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserEnableTwoFactorAuthenticatorPost: async (enableTwoFactorModel?: EnableTwoFactorModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/EnableTwoFactorAuthenticator`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(enableTwoFactorModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGenerateTwoFactorInfoGet: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/GenerateTwoFactorInfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetTagUserCommand} [getTagUserCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGetTagUserPost: async (getTagUserCommand?: GetTagUserCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/GetTagUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getTagUserCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetUsersQuery} [getUsersQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGetUsersByCompanyPost: async (getUsersQuery?: GetUsersQuery, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/GetUsersByCompany`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getUsersQuery, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GenerateUserTokenQuery} [generateUserTokenQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserLoginLegalPost: async (generateUserTokenQuery?: GenerateUserTokenQuery, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/LoginLegal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(generateUserTokenQuery, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GenerateUserTokenQuery} [generateUserTokenQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserLoginPost: async (generateUserTokenQuery?: GenerateUserTokenQuery, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/Login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(generateUserTokenQuery, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserLogoutPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/Logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RefreshUserTokenCommand} [refreshUserTokenCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserRefreshSignInPost: async (refreshUserTokenCommand?: RefreshUserTokenCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/RefreshSignIn`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(refreshUserTokenCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CompanyRegisterCommand} [companyRegisterCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserRegisterCompanyPost: async (companyRegisterCommand?: CompanyRegisterCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/RegisterCompany`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(companyRegisterCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserNomalCreateCommand} [userNomalCreateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserRegisterUserPost: async (userNomalCreateCommand?: UserNomalCreateCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/RegisterUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userNomalCreateCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RemoveRoleSysAdminCommand} [removeRoleSysAdminCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserRemoveRoleSysAdminPost: async (removeRoleSysAdminCommand?: RemoveRoleSysAdminCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/RemoveRoleSysAdmin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(removeRoleSysAdminCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RemoveUserRoleCommand} [removeUserRoleCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserRemoveUserRolePut: async (removeUserRoleCommand?: RemoveUserRoleCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/RemoveUserRole`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(removeUserRoleCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserSetRoleCommand} [userSetRoleCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserSetRolePut: async (userSetRoleCommand?: UserSetRoleCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/SetRole`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userSetRoleCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserTokenRequestQuery} [userTokenRequestQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserTokenRequestPost: async (userTokenRequestQuery?: UserTokenRequestQuery, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/TokenRequest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userTokenRequestQuery, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateTagUserCommand} [updateTagUserCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserUpdateTagUserPut: async (updateTagUserCommand?: UpdateTagUserCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/UpdateTagUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateTagUserCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserNomalCreateCommand} [userNomalCreateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserUserNomalCreatePost: async (userNomalCreateCommand?: UserNomalCreateCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/UserNomalCreate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userNomalCreateCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TwoStepLoginModel} [twoStepLoginModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserVerifyTwoFactorAuthenticationPost: async (twoStepLoginModel?: TwoStepLoginModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/VerifyTwoFactorAuthentication`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(twoStepLoginModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AddRoleSysAdminCommand} [addRoleSysAdminCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserAddRoleSysAdminPost(addRoleSysAdminCommand?: AddRoleSysAdminCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanOperationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserAddRoleSysAdminPost(addRoleSysAdminCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ChangePasswordCommand} [changePasswordCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserChangePasswordPost(changePasswordCommand?: ChangePasswordCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanOperationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserChangePasswordPost(changePasswordCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {object} [command] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserCheckUserStatusGet(command?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserStatusResultOperationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserCheckUserStatusGet(command, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UserSysAdminCreateCommand} [userSysAdminCreateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserCreateSysAdminPost(userSysAdminCreateCommand?: UserSysAdminCreateCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserCreateCommandResultOperationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserCreateSysAdminPost(userSysAdminCreateCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UserEditCommand} [userEditCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserEditPut(userEditCommand?: UserEditCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserEditCommandResultOperationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserEditPut(userEditCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EnableTwoFactorModel} [enableTwoFactorModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserEnableTwoFactorAuthenticatorPost(enableTwoFactorModel?: EnableTwoFactorModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccessTokenOperationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserEnableTwoFactorAuthenticatorPost(enableTwoFactorModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserGenerateTwoFactorInfoGet(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenerateTwoFactorInfoResultOperationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserGenerateTwoFactorInfoGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {GetTagUserCommand} [getTagUserCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserGetTagUserPost(getTagUserCommand?: GetTagUserCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StringOperationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserGetTagUserPost(getTagUserCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {GetUsersQuery} [getUsersQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserGetUsersByCompanyPost(getUsersQuery?: GetUsersQuery, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserIPagedListOperationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserGetUsersByCompanyPost(getUsersQuery, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {GenerateUserTokenQuery} [generateUserTokenQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserLoginLegalPost(generateUserTokenQuery?: GenerateUserTokenQuery, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccessTokenOperationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserLoginLegalPost(generateUserTokenQuery, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {GenerateUserTokenQuery} [generateUserTokenQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserLoginPost(generateUserTokenQuery?: GenerateUserTokenQuery, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccessTokenOperationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserLoginPost(generateUserTokenQuery, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserLogoutPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserLogoutPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RefreshUserTokenCommand} [refreshUserTokenCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserRefreshSignInPost(refreshUserTokenCommand?: RefreshUserTokenCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserRefreshSignInPost(refreshUserTokenCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CompanyRegisterCommand} [companyRegisterCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserRegisterCompanyPost(companyRegisterCommand?: CompanyRegisterCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyRegisterCommandResultOperationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserRegisterCompanyPost(companyRegisterCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UserNomalCreateCommand} [userNomalCreateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserRegisterUserPost(userNomalCreateCommand?: UserNomalCreateCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserCreateCommandResultOperationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserRegisterUserPost(userNomalCreateCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RemoveRoleSysAdminCommand} [removeRoleSysAdminCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserRemoveRoleSysAdminPost(removeRoleSysAdminCommand?: RemoveRoleSysAdminCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanOperationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserRemoveRoleSysAdminPost(removeRoleSysAdminCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RemoveUserRoleCommand} [removeUserRoleCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserRemoveUserRolePut(removeUserRoleCommand?: RemoveUserRoleCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RemoveUserRoleCommandResultOperationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserRemoveUserRolePut(removeUserRoleCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UserSetRoleCommand} [userSetRoleCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserSetRolePut(userSetRoleCommand?: UserSetRoleCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserSetRoleCommandResultOperationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserSetRolePut(userSetRoleCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UserTokenRequestQuery} [userTokenRequestQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserTokenRequestPost(userTokenRequestQuery?: UserTokenRequestQuery, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserTokenRequestPost(userTokenRequestQuery, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateTagUserCommand} [updateTagUserCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserUpdateTagUserPut(updateTagUserCommand?: UpdateTagUserCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanOperationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserUpdateTagUserPut(updateTagUserCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UserNomalCreateCommand} [userNomalCreateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserUserNomalCreatePost(userNomalCreateCommand?: UserNomalCreateCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserCreateCommandResultOperationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserUserNomalCreatePost(userNomalCreateCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TwoStepLoginModel} [twoStepLoginModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserVerifyTwoFactorAuthenticationPost(twoStepLoginModel?: TwoStepLoginModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccessTokenOperationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserVerifyTwoFactorAuthenticationPost(twoStepLoginModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @param {AddRoleSysAdminCommand} [addRoleSysAdminCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserAddRoleSysAdminPost(addRoleSysAdminCommand?: AddRoleSysAdminCommand, options?: any): AxiosPromise<BooleanOperationResult> {
            return localVarFp.apiUserAddRoleSysAdminPost(addRoleSysAdminCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ChangePasswordCommand} [changePasswordCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserChangePasswordPost(changePasswordCommand?: ChangePasswordCommand, options?: any): AxiosPromise<BooleanOperationResult> {
            return localVarFp.apiUserChangePasswordPost(changePasswordCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {object} [command] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserCheckUserStatusGet(command?: object, options?: any): AxiosPromise<UserStatusResultOperationResult> {
            return localVarFp.apiUserCheckUserStatusGet(command, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserSysAdminCreateCommand} [userSysAdminCreateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserCreateSysAdminPost(userSysAdminCreateCommand?: UserSysAdminCreateCommand, options?: any): AxiosPromise<UserCreateCommandResultOperationResult> {
            return localVarFp.apiUserCreateSysAdminPost(userSysAdminCreateCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserEditCommand} [userEditCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserEditPut(userEditCommand?: UserEditCommand, options?: any): AxiosPromise<UserEditCommandResultOperationResult> {
            return localVarFp.apiUserEditPut(userEditCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EnableTwoFactorModel} [enableTwoFactorModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserEnableTwoFactorAuthenticatorPost(enableTwoFactorModel?: EnableTwoFactorModel, options?: any): AxiosPromise<AccessTokenOperationResult> {
            return localVarFp.apiUserEnableTwoFactorAuthenticatorPost(enableTwoFactorModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGenerateTwoFactorInfoGet(id?: string, options?: any): AxiosPromise<GenerateTwoFactorInfoResultOperationResult> {
            return localVarFp.apiUserGenerateTwoFactorInfoGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetTagUserCommand} [getTagUserCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGetTagUserPost(getTagUserCommand?: GetTagUserCommand, options?: any): AxiosPromise<StringOperationResult> {
            return localVarFp.apiUserGetTagUserPost(getTagUserCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetUsersQuery} [getUsersQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGetUsersByCompanyPost(getUsersQuery?: GetUsersQuery, options?: any): AxiosPromise<UserIPagedListOperationResult> {
            return localVarFp.apiUserGetUsersByCompanyPost(getUsersQuery, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GenerateUserTokenQuery} [generateUserTokenQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserLoginLegalPost(generateUserTokenQuery?: GenerateUserTokenQuery, options?: any): AxiosPromise<AccessTokenOperationResult> {
            return localVarFp.apiUserLoginLegalPost(generateUserTokenQuery, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GenerateUserTokenQuery} [generateUserTokenQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserLoginPost(generateUserTokenQuery?: GenerateUserTokenQuery, options?: any): AxiosPromise<AccessTokenOperationResult> {
            return localVarFp.apiUserLoginPost(generateUserTokenQuery, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserLogoutPost(options?: any): AxiosPromise<void> {
            return localVarFp.apiUserLogoutPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RefreshUserTokenCommand} [refreshUserTokenCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserRefreshSignInPost(refreshUserTokenCommand?: RefreshUserTokenCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiUserRefreshSignInPost(refreshUserTokenCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CompanyRegisterCommand} [companyRegisterCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserRegisterCompanyPost(companyRegisterCommand?: CompanyRegisterCommand, options?: any): AxiosPromise<CompanyRegisterCommandResultOperationResult> {
            return localVarFp.apiUserRegisterCompanyPost(companyRegisterCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserNomalCreateCommand} [userNomalCreateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserRegisterUserPost(userNomalCreateCommand?: UserNomalCreateCommand, options?: any): AxiosPromise<UserCreateCommandResultOperationResult> {
            return localVarFp.apiUserRegisterUserPost(userNomalCreateCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RemoveRoleSysAdminCommand} [removeRoleSysAdminCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserRemoveRoleSysAdminPost(removeRoleSysAdminCommand?: RemoveRoleSysAdminCommand, options?: any): AxiosPromise<BooleanOperationResult> {
            return localVarFp.apiUserRemoveRoleSysAdminPost(removeRoleSysAdminCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RemoveUserRoleCommand} [removeUserRoleCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserRemoveUserRolePut(removeUserRoleCommand?: RemoveUserRoleCommand, options?: any): AxiosPromise<RemoveUserRoleCommandResultOperationResult> {
            return localVarFp.apiUserRemoveUserRolePut(removeUserRoleCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserSetRoleCommand} [userSetRoleCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserSetRolePut(userSetRoleCommand?: UserSetRoleCommand, options?: any): AxiosPromise<UserSetRoleCommandResultOperationResult> {
            return localVarFp.apiUserSetRolePut(userSetRoleCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserTokenRequestQuery} [userTokenRequestQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserTokenRequestPost(userTokenRequestQuery?: UserTokenRequestQuery, options?: any): AxiosPromise<void> {
            return localVarFp.apiUserTokenRequestPost(userTokenRequestQuery, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateTagUserCommand} [updateTagUserCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserUpdateTagUserPut(updateTagUserCommand?: UpdateTagUserCommand, options?: any): AxiosPromise<BooleanOperationResult> {
            return localVarFp.apiUserUpdateTagUserPut(updateTagUserCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserNomalCreateCommand} [userNomalCreateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserUserNomalCreatePost(userNomalCreateCommand?: UserNomalCreateCommand, options?: any): AxiosPromise<UserCreateCommandResultOperationResult> {
            return localVarFp.apiUserUserNomalCreatePost(userNomalCreateCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TwoStepLoginModel} [twoStepLoginModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserVerifyTwoFactorAuthenticationPost(twoStepLoginModel?: TwoStepLoginModel, options?: any): AxiosPromise<AccessTokenOperationResult> {
            return localVarFp.apiUserVerifyTwoFactorAuthenticationPost(twoStepLoginModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @param {AddRoleSysAdminCommand} [addRoleSysAdminCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserAddRoleSysAdminPost(addRoleSysAdminCommand?: AddRoleSysAdminCommand, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserAddRoleSysAdminPost(addRoleSysAdminCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ChangePasswordCommand} [changePasswordCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserChangePasswordPost(changePasswordCommand?: ChangePasswordCommand, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserChangePasswordPost(changePasswordCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {object} [command] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserCheckUserStatusGet(command?: object, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserCheckUserStatusGet(command, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserSysAdminCreateCommand} [userSysAdminCreateCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserCreateSysAdminPost(userSysAdminCreateCommand?: UserSysAdminCreateCommand, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserCreateSysAdminPost(userSysAdminCreateCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserEditCommand} [userEditCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserEditPut(userEditCommand?: UserEditCommand, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserEditPut(userEditCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EnableTwoFactorModel} [enableTwoFactorModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserEnableTwoFactorAuthenticatorPost(enableTwoFactorModel?: EnableTwoFactorModel, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserEnableTwoFactorAuthenticatorPost(enableTwoFactorModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserGenerateTwoFactorInfoGet(id?: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserGenerateTwoFactorInfoGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GetTagUserCommand} [getTagUserCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserGetTagUserPost(getTagUserCommand?: GetTagUserCommand, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserGetTagUserPost(getTagUserCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GetUsersQuery} [getUsersQuery] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserGetUsersByCompanyPost(getUsersQuery?: GetUsersQuery, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserGetUsersByCompanyPost(getUsersQuery, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GenerateUserTokenQuery} [generateUserTokenQuery] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserLoginLegalPost(generateUserTokenQuery?: GenerateUserTokenQuery, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserLoginLegalPost(generateUserTokenQuery, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GenerateUserTokenQuery} [generateUserTokenQuery] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserLoginPost(generateUserTokenQuery?: GenerateUserTokenQuery, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserLoginPost(generateUserTokenQuery, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserLogoutPost(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserLogoutPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RefreshUserTokenCommand} [refreshUserTokenCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserRefreshSignInPost(refreshUserTokenCommand?: RefreshUserTokenCommand, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserRefreshSignInPost(refreshUserTokenCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CompanyRegisterCommand} [companyRegisterCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserRegisterCompanyPost(companyRegisterCommand?: CompanyRegisterCommand, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserRegisterCompanyPost(companyRegisterCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserNomalCreateCommand} [userNomalCreateCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserRegisterUserPost(userNomalCreateCommand?: UserNomalCreateCommand, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserRegisterUserPost(userNomalCreateCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RemoveRoleSysAdminCommand} [removeRoleSysAdminCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserRemoveRoleSysAdminPost(removeRoleSysAdminCommand?: RemoveRoleSysAdminCommand, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserRemoveRoleSysAdminPost(removeRoleSysAdminCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RemoveUserRoleCommand} [removeUserRoleCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserRemoveUserRolePut(removeUserRoleCommand?: RemoveUserRoleCommand, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserRemoveUserRolePut(removeUserRoleCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserSetRoleCommand} [userSetRoleCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserSetRolePut(userSetRoleCommand?: UserSetRoleCommand, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserSetRolePut(userSetRoleCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserTokenRequestQuery} [userTokenRequestQuery] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserTokenRequestPost(userTokenRequestQuery?: UserTokenRequestQuery, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserTokenRequestPost(userTokenRequestQuery, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateTagUserCommand} [updateTagUserCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserUpdateTagUserPut(updateTagUserCommand?: UpdateTagUserCommand, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserUpdateTagUserPut(updateTagUserCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserNomalCreateCommand} [userNomalCreateCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserUserNomalCreatePost(userNomalCreateCommand?: UserNomalCreateCommand, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserUserNomalCreatePost(userNomalCreateCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TwoStepLoginModel} [twoStepLoginModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserVerifyTwoFactorAuthenticationPost(twoStepLoginModel?: TwoStepLoginModel, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserVerifyTwoFactorAuthenticationPost(twoStepLoginModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserApprovalStampTemplateApi - axios parameter creator
 * @export
 */
export const UserApprovalStampTemplateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApprovalStampTemplateGetUserApprovalStampTemplatesByUserGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApprovalStampTemplate/GetUserApprovalStampTemplatesByUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ImportUserApprovalStampTemplateParam} [importUserApprovalStampTemplateParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApprovalStampTemplateSaveUserApprovalStampTemplatePost: async (importUserApprovalStampTemplateParam?: ImportUserApprovalStampTemplateParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApprovalStampTemplate/SaveUserApprovalStampTemplate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(importUserApprovalStampTemplateParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApprovalStampTemplateApi - functional programming interface
 * @export
 */
export const UserApprovalStampTemplateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApprovalStampTemplateApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApprovalStampTemplateGetUserApprovalStampTemplatesByUserGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserApprovalStampTemplate>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApprovalStampTemplateGetUserApprovalStampTemplatesByUserGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ImportUserApprovalStampTemplateParam} [importUserApprovalStampTemplateParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApprovalStampTemplateSaveUserApprovalStampTemplatePost(importUserApprovalStampTemplateParam?: ImportUserApprovalStampTemplateParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApprovalStampTemplateSaveUserApprovalStampTemplatePost(importUserApprovalStampTemplateParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApprovalStampTemplateApi - factory interface
 * @export
 */
export const UserApprovalStampTemplateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApprovalStampTemplateApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApprovalStampTemplateGetUserApprovalStampTemplatesByUserGet(options?: any): AxiosPromise<Array<UserApprovalStampTemplate>> {
            return localVarFp.apiApprovalStampTemplateGetUserApprovalStampTemplatesByUserGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ImportUserApprovalStampTemplateParam} [importUserApprovalStampTemplateParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApprovalStampTemplateSaveUserApprovalStampTemplatePost(importUserApprovalStampTemplateParam?: ImportUserApprovalStampTemplateParam, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiApprovalStampTemplateSaveUserApprovalStampTemplatePost(importUserApprovalStampTemplateParam, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApprovalStampTemplateApi - object-oriented interface
 * @export
 * @class UserApprovalStampTemplateApi
 * @extends {BaseAPI}
 */
export class UserApprovalStampTemplateApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApprovalStampTemplateApi
     */
    public apiApprovalStampTemplateGetUserApprovalStampTemplatesByUserGet(options?: AxiosRequestConfig) {
        return UserApprovalStampTemplateApiFp(this.configuration).apiApprovalStampTemplateGetUserApprovalStampTemplatesByUserGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ImportUserApprovalStampTemplateParam} [importUserApprovalStampTemplateParam] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApprovalStampTemplateApi
     */
    public apiApprovalStampTemplateSaveUserApprovalStampTemplatePost(importUserApprovalStampTemplateParam?: ImportUserApprovalStampTemplateParam, options?: AxiosRequestConfig) {
        return UserApprovalStampTemplateApiFp(this.configuration).apiApprovalStampTemplateSaveUserApprovalStampTemplatePost(importUserApprovalStampTemplateParam, options).then((request) => request(this.axios, this.basePath));
    }
}


