import {
  ButtonAction,
  FormStatus,
  Role,
} from "../../../../common/constant/constant";
import { useFormik } from "formik";
import * as yup from "yup";
import * as Api from "../../../../api/src/index";
import { useEffect, useRef, useState } from "react";
import { UserModalProps } from "../modal/user";
import { useRecoilState } from "recoil";
import { MsgState } from "../../../../recoil/msgState";
import { DialogState } from "../../../../recoil/dialogState";
import { get } from "http";
export interface UserAdminFormObj extends Api.UserSysAdminCreateParam {
  confirmPassword?: string;
}

yup.addMethod(
  yup.string,
  "checkConfirmPassword",
  function checkConfirmPassword(errorMessage) {
    return this.test("checkConfirmPassword", errorMessage, function (value) {
      const { path, createError, parent } = this;
      if (parent.password !== parent.confirmPassword) {
        return createError({ path, message: errorMessage });
      }
      return true;
    });
  }
);
const validationSchema = yup.object({
  firstName: yup.string().required("First name is a required field"),
  lastName: yup.string().required("Last name is a required field"),
  email: yup.string().email().required("Email is a required field"),
  password: yup.string().min(6, "Password at least 6 character please"),
  confirmPassword: (yup.string() as any).checkConfirmPassword(
    "Confirm  password doesn't match"
  ),
});
const defaultObj = {
  id: 0,
  userName: "",
  firstName: "",
  lastName: "",
  phoneNumber: "",
  address: "",
  email: "",
  passWord: "",
  companyId: undefined,
  status: Api.RowStatus.Active,
} as UserAdminFormObj;
const UserApi = new Api.UserApi();
const CompanyApi = new Api.CompanyApi();
export const userAdminService = ({
  userItem,
  formStatus,
  onClose,
}: UserModalProps) => {
  const [, SetMsgState] = useRecoilState(MsgState);
  const formikUser = useFormik({
    initialValues: userItem || defaultObj,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      let res: any | null = null;
      values = { ...values, userName: values.email };
      if (formStatus === "Add") {
        values.status = Api.RowStatus.Active;
        res = await UserApi.apiUserCreateSysAdminPost({ param: values });
      } else {
        res = await UserApi.apiUserEditPut({ param: values });
      }
      if (res && res.data.errorMessage) {
        SetMsgState((cur) => {
          return { ...cur, Msg: res?.data.errorMessage || "", show: true };
        });
      }
      if (res && res.data.isSuccess && onClose) {
        onClose("Save");
      }
    },
  });
  return { formikUser };
};
export interface AddRoleSysAdminProps {
  onClose?: (action: ButtonAction) => void;
  show: boolean;
}
export interface ListUserQuery {
  keyword: string;
  companyId: number;
  page: number;
  limit: number;
}
const defaultQuery = {
  keyword: "",
  companyId: 0,
  page: 0,
  limit: 25,
};
export const listUsersAdminService = () => {
  const [anchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [, setPropsDialog] = useRecoilState(DialogState);
  const openMenu = Boolean(anchorEl);
  const handleShowMenuClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    item: Api.Company
  ) => {
    setMenuAnchorEl(event.currentTarget);
    setRowSelect(item);
  };
  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };
  const [userModalProp, setUserModalProp] = useState<UserModalProps>({
    formStatus: "",
    userItem: null,
  } as UserModalProps);
  const [addRoleSysAdminModalProp, setAddRoleSysAdminModalProp] = useState({
    show: false,
  });
  const [query, setQuery] = useState<Api.GetUserByCompanyParam>();
  const [rowSelected, setRowSelect] = useState<Api.User | null>(null);
  const [listData, setListData] =
    useState<Api.UserIPagedListOperationResult | null>(null);
  const [listCompanyData, setListCompany] = useState<Api.Company[]>([]);
  const init = useRef(false);
  useEffect(() => {
    if (init.current) return;
    init.current = true;
    setQuery(defaultQuery);
    getAllCompany();
  }, []);
  useEffect(() => {
    query && getUsers(query);
  }, [query]);
  const getUsers = async (param: Api.GetUserByCompanyParam) => {
    const data = await UserApi.apiUserGetUsersByCompanyPost({
      param: { ...param, userRole: ["sysadmin"], page: (param.page || 0) + 1 },
    });
    setListData(data.data);
  };
  const getAllCompany = async () => {
    const data = await CompanyApi.apiCompanyGetAllCompanysGet("", 1, 1000000);
    setListCompany(data?.data?.result?.items || []);
  };
  const onPageChange = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number
  ) => {
    setQuery((cur) => {
      if (cur) return { ...cur, page: page };
    });
  };
  const onRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setQuery((cur) => {
      if (cur) return { ...cur, limit: parseInt(event.target.value) };
    });
  };
  const onKeyWordBlur = (value: string) => {
    if (query && value != query.keyWord) {
      setQuery((cur) => {
        if (cur) return { ...cur, keyWord: value };
      });
    }
  };
  const onChangeComapny = (companyId: number) => {
    if (query && companyId !== query.companyId) {
      setQuery((cur) => {
        if (cur) return { ...cur, companyId: companyId };
      });
    }
  };
  const AddNew = () => {
    setUserModalProp((cur) => {
      return { ...cur, formStatus: "Add", userItem: null };
    });
  };
  const SetUserRoleSysAdmin = () => {
    setAddRoleSysAdminModalProp((cur) => {
      return { ...cur, show: true };
    });
  };
  const EditObj = () => {
    setUserModalProp((cur) => {
      return {
        ...cur,
        formStatus: "Edit",
        userItem: {
          id: rowSelected?.id,
          userName: rowSelected?.userName,
          firstName: rowSelected?.firstName,
          lastName: rowSelected?.lastName,
          phoneNumber: rowSelected?.phoneNumber,
          address: rowSelected?.address,
          email: rowSelected?.email,
          companyId: rowSelected?.companyId,
          status: rowSelected?.status,
          twoFactorEnabled: rowSelected?.twoFactorEnabled,
          password: "",
          confirmPassword: "",
        },
      };
    });
    handleMenuClose();
  };
  const updateStatus = async (rowStatus: Api.RowStatus) => {
    if (rowStatus === "Delete") {
      setPropsDialog({
        Msg: "Are you sure you want to delete this item?",
        show: true,
        DialogType: "Confirm",
        ActtionOK: () => {
          deleteItem(rowStatus);
        },
      });
    }
  };
  const deleteItem = async (rowStatus: Api.RowStatus) => {
    await UserApi.apiUserEditPut({
      param: {
        id: rowSelected?.id,
        userName: rowSelected?.userName,
        firstName: rowSelected?.firstName,
        lastName: rowSelected?.lastName,
        phoneNumber: rowSelected?.phoneNumber,
        address: rowSelected?.address,
        email: rowSelected?.email,
        companyId: rowSelected?.companyId,
        twoFactorEnabled: rowSelected?.twoFactorEnabled,
        status: rowStatus,
      },
    });
    query && getUsers(query);
    handleMenuClose();
  };
  const removeUserRoleAdmin = async () => {
    handleMenuClose();
    setPropsDialog({
      Msg:
        "Are you sure you want to delete the System Administrator role for " +
        rowSelected?.email +
        "?",
      show: true,
      DialogType: "Confirm",
      ActtionOK: async () => {
        await UserApi.apiUserRemoveUserRolePut({
          param: { id: rowSelected?.id, role: Role.sysadmin },
        });
        query && getUsers(query);
      },
    });
  };
  const closeModal = () => {
    setUserModalProp((cur) => {
      return { ...cur, formStatus: "", userItem: null };
    });
  };
  const closeRoleSysAdminModal = () => {
    setAddRoleSysAdminModalProp({ show: false });
    query && getUsers(query);
  };
  return {
    handleShowMenuClick,
    handleMenuClose,
    AddNew,
    EditObj,
    getUsers,
    listData,
    anchorEl,
    query,
    userModalProp,
    onPageChange,
    onRowsPerPageChange,
    onKeyWordBlur,
    openMenu,
    closeModal,
    updateStatus,
    onChangeComapny,
    listCompanyData,
    addRoleSysAdminModalProp,
    SetUserRoleSysAdmin,
    closeRoleSysAdminModal,
    removeUserRoleAdmin,
  };
};
export const AddRoleSysAdminService = (props: AddRoleSysAdminProps) => {
  const { show, onClose } = props;
  const [listUserData, setListUserData] =
    useState<Api.UserIPagedListOperationResult | null>(null);
  const [query, setQuery] = useState<Api.GetUserByCompanyParam>({
    keyWord: "",
    companyId: 0,
    page: 0,
    limit: 25,
  });
  const [userSelecteds, setUserSelecteds] = useState<number[]>([]);
  const getUsers = async (param: Api.GetUserByCompanyParam) => {
    const data = await UserApi.apiUserGetUsersByCompanyPost({
      param: {
        ...param,
        userRole: [Role.comadmin, ""],
        page: (param.page || 0) + 1,
      },
    });
    setListUserData(data.data);
  };
  const onKeyWordBlur = (value: string) => {
    if (query && value != query.keyWord) {
      setQuery((cur) => {
        return { ...cur, keyWord: value };
      });
    }
  };
  useEffect(() => {
    query && getUsers(query);
  }, [query]);
  const formik = useFormik({
    initialValues: { users: [] as number[] },
    onSubmit: async (values) => {
      const res = await UserApi.apiUserAddRoleSysAdminPost({
        param: { ids: values.users },
      });
      onClose && onClose("Close");
    },
  });
  const userSelectedsChange = (user?: Api.User) => {
    if (formik.values.users.includes(user?.id || 0)) {
      formik.setFieldValue(
        "users",
        formik.values.users.filter((o) => o !== user?.id)
      );
    } else {
      formik.setFieldValue("users", [...formik.values.users, user?.id || 0]);
    }
  };
  const onPageChange = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number
  ) => {
    setQuery((cur) => {
      return { ...cur, page: page };
    });
  };
  const onRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setQuery((cur) => {
      return { ...cur, limit: parseInt(event.target.value) };
    });
  };
  return {
    listUserData,
    formik,
    query,
    onPageChange,
    onRowsPerPageChange,
    userSelectedsChange,
    userSelecteds,
    onKeyWordBlur,
  };
};
