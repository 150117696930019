import QRCode from "qrcode";
import React from "react";
import * as Api from "../../../api/src";
import { useLocation } from "react-router-dom";
import { Button, TextField } from "@mui/material";
import { LocalStorageKey } from "../../../common/constant/constant";
import { useRecoilState } from "recoil";
import { DialogState } from "../../../recoil/dialogState";
const UserApi = new Api.UserApi();
export const EnableTwoFactorAuthenticator = () => {
  const [propsDialog, setPropsDialog] = useRecoilState(DialogState);
  const [formData, setFormData] = React.useState<
    Api.EnableTwoFactorModel & { img?: string }
  >();
  const { search } = useLocation();
  const GetQACode = async () => {
    UserApi.apiUserGenerateTwoFactorInfoGet(search.replace("?id=", "")).then(
      (res) => {
        if (res && res.data.result?.qrCodeUrl) {
          QRCode.toDataURL(res.data.result?.qrCodeUrl).then((img) => {
            setFormData((cur) => {
              return {
                ...cur,
                secretKey: res.data.result?.secretKey,
                authenticatorUri: res.data.result?.qrCodeUrl,
                id: search.replace("?id=", ""),
                img: img,
              };
            });
          });
        }
      }
    );
  };

  const Verify_Click = () => {
    UserApi.apiUserEnableTwoFactorAuthenticatorPost(formData).then((res) => {
      if (res && res.data.result && res.data.isSuccess) {
        localStorage.setItem(
          LocalStorageKey.UserInfo,
          JSON.stringify(res.data.result)
        );
        window.location.href = "/users";
      } else {
        setPropsDialog({
          Msg: res.data.errorMessage || "",
          show: true,
          DialogType: "Alert",
        });
      }
    });
  };
  React.useEffect(() => {
    GetQACode();
  }, []);
  return (
    <div
      className="EnableTwoFactor"
      style={{ margin: "80px auto", width: "60%" }}
    >
      <h4>Enable authenticator</h4>
      <p>To use an authenticator app go through the following steps:</p>
      <ol className="list">
        <li>
          <p>
            Download a two-factor authenticator app like Microsoft Authenticator
            for{" "}
            <a
              href="https://go.microsoft.com/fwlink/?Linkid=825071"
              rel="noopener"
            >
              Windows Phone
            </a>
            ,{" "}
            <a
              href="https://go.microsoft.com/fwlink/?Linkid=825072"
              rel="noopener"
            >
              Android
            </a>{" "}
            and{" "}
            <a
              href="https://go.microsoft.com/fwlink/?Linkid=825073"
              rel="noopener"
            >
              iOS
            </a>
          </p>
        </li>
        <li>
          <p>
            Scan the QR Code or enter this key{" "}
            <kbd
              style={{
                backgroundColor: "#333",
                color: "#fff",
              }}
            >
              {formData?.secretKey}
            </kbd>{" "}
            into your two factor authenticator app. Spaces and casing do not
            matter.
          </p>
          <div className="alert alert-info">
            To enable QR code generation please read our{" "}
            <a
              href="https://go.microsoft.com/fwlink/?Linkid=852423"
              rel="noopener"
            >
              documentation
            </a>
            .
          </div>
          <div id="qrCode">
            <img src={formData?.img} alt="QR Code" width={250} />
          </div>
          <div id="qrCodeData" data-url="@Model.AuthenticatorUri"></div>
        </li>
        <li>
          <p>
            Once you have scanned the QR code or input the key above, your two
            factor authentication app will provide you with a unique code. Enter
            the code in the confirmation box below.
          </p>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <TextField
                  label="Verification Code"
                  variant="standard"
                  style={{ width: "100%" }}
                  size="small"
                  onChange={(e) => {
                    setFormData((cur) => {
                      return { ...cur, code: e.target.value };
                    });
                  }}
                />
              </div>
              <br />
              <Button
                variant="contained"
                onClick={() => {
                  Verify_Click();
                }}
              >
                Verify
              </Button>
            </div>
          </div>
        </li>
      </ol>
    </div>
  );
};
