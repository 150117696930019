import QRCode from "qrcode";
import React from "react";
import * as Api from "../../../api/src";
import { useLocation } from "react-router-dom";
import { Button, TextField } from "@mui/material";
import { LocalStorageKey } from "../../../common/constant/constant";
import { useRecoilState } from "recoil";
import { DialogState } from "../../../recoil/dialogState";
const UserApi = new Api.UserApi();
export const VerifyTwoFactorAuthentication = () => {
  const [propsDialog, setPropsDialog] = useRecoilState(DialogState);
  const [formData, setFormData] = React.useState<Api.TwoStepLoginModel>();
  const { search } = useLocation();

  const Verify_Click = () => {
    UserApi.apiUserVerifyTwoFactorAuthenticationPost(formData).then((res) => {
      if (res && res.data.result && res.data.isSuccess) {
        localStorage.setItem(
          LocalStorageKey.UserInfo,
          JSON.stringify(res.data.result)
        );
        window.location.href = "/users";
      } else {
        setPropsDialog({
          Msg: res.data.errorMessage || "",
          show: true,
          DialogType: "Alert",
        });
      }
    });
  };
  React.useEffect(() => {
    setFormData({ code: "", id: search.replace("?id=", "") });
  }, []);
  return (
    <div
      className="EnableTwoFactor"
      style={{ margin: "80px auto", width: "60%" }}
    >
      <h4>Two Factor validation</h4>
      <p>To use an authenticator app go through the following steps:</p>
      <ul className="list">
        <li>
          Please enter the two-factor validation code from your Authenticator
          app into the textbox below and click Validate button.
        </li>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <TextField
                label="Verification Code"
                variant="standard"
                style={{ width: "100%" }}
                size="small"
                onChange={(e) => {
                  setFormData((cur) => {
                    return { ...cur, code: e.target.value };
                  });
                }}
              />
            </div>
            <br />
            <Button
              variant="contained"
              onClick={() => {
                Verify_Click();
              }}
            >
              Verify
            </Button>
          </div>
        </div>
      </ul>
    </div>
  );
};
