import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Grid,
  Modal,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
} from "@mui/material";
import * as Api from "../../../../api/src/index";
import React from "react";
import {
  AddRoleSysAdminProps,
  AddRoleSysAdminService,
} from "../service/userAdminService";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 900,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 3,
};
interface HeadCell {
  disablePadding: boolean;
  id: string;
  label: string;
  numeric: boolean;
}
const headCells: readonly HeadCell[] = [
  {
    id: "checkbox",
    numeric: false,
    disablePadding: false,
    label: "",
  },
  {
    id: "firstName",
    numeric: false,
    disablePadding: false,
    label: "Name",
  },
  {
    id: "email",
    numeric: false,
    disablePadding: false,
    label: "Email",
  },
  {
    id: "company",
    numeric: false,
    disablePadding: false,
    label: "Company",
  },
];
interface EnhancedTableProps {
  numSelected?: number;
  onRequestSort?: (event: React.MouseEvent<unknown>, property: string) => void;
  orderBy?: string;
  rowCount?: number;
}
const AddRoleSysAdmin = ({ show, onClose }: AddRoleSysAdminProps) => {
  const {
    listUserData,
    query,
    formik,
    onPageChange,
    onRowsPerPageChange,
    userSelectedsChange,
    userSelecteds,
    onKeyWordBlur,
  } = AddRoleSysAdminService({
    show,
    onClose,
  });

  function EnhancedTableHead(props: EnhancedTableProps) {
    const { orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler =
      (property: string) => (event: React.MouseEvent<unknown>) => {
        onRequestSort && onRequestSort(event, property);
      };

    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  return (
    <Modal open={show}>
      <Box sx={style}>
        <Typography style={{ marginBottom: 24 }} variant="h5">
          <b> User</b>
        </Typography>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                size="small"
                onBlur={(e) => {
                  onKeyWordBlur(e.target.value);
                }}
                label="Key word"
              />
            </Grid>
            <Grid item xs={12}>
              <TableContainer>
                <Table
                  sx={{ Width: 750 }}
                  aria-labelledby="tableTitle"
                  size={"small"}
                >
                  <EnhancedTableHead />
                  <TableBody>
                    {listUserData?.result?.items?.map((row, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          tabIndex={-1}
                          key={row.id}
                          sx={{ cursor: "pointer" }}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                              checked={formik.values.users.includes(
                                row.id || 0
                              )}
                              onChange={(e) => {
                                userSelectedsChange(row);
                              }}
                            />
                          </TableCell>
                          <TableCell component="th" id={labelId} scope="row">
                            {`${row.firstName} ${row.lastName}`}
                          </TableCell>
                          <TableCell align="left">{row.email}</TableCell>
                          <TableCell align="left">
                            {row.company?.name}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={listUserData?.result?.total || 0}
                rowsPerPage={query?.limit || 0}
                page={query?.page || 0}
                onPageChange={onPageChange}
                onRowsPerPageChange={(e) => {
                  onRowsPerPageChange(e);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Stack spacing={2} direction="row" justifyContent={"flex-end"}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    onClose && onClose("Close");
                  }}
                >
                  Close
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    formik.handleSubmit();
                  }}
                >
                  Save
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
};
export default AddRoleSysAdmin;
