import {
  Autocomplete,
  Box,
  Button,
  Grid,
  Modal,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import {
  ButtonAction,
  FormStatus,
  Role,
} from "../../../../common/constant/constant";
import { UserFormObj, userService } from "../service/userService";
import * as Api from "../../../../api/src";
import { AuthenService } from "../../../../services/authenServices";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 3,
};
export interface UserModalProps {
  formStatus: FormStatus;
  userItem: UserFormObj | null;
  onClose?: (action: ButtonAction) => void;
  companys: Api.Company[];
}

const UserModal = ({
  formStatus,
  userItem,
  onClose,
  companys,
}: UserModalProps) => {
  const { formikUser, getSelectedValue, Countrys } = userService({
    formStatus: formStatus,
    userItem: userItem,
    onClose: onClose,
    companys: companys,
  });
  const { GetRoles } = AuthenService();
  return (
    <Modal open={!!formStatus}>
      <Box sx={style}>
        <Typography style={{ marginBottom: 24 }} variant="h5">
          <b> User</b>
        </Typography>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                label="First name"
                required
                InputLabelProps={{
                  shrink: true,
                }}
                name="firstName"
                value={formikUser.values.firstName}
                error={
                  formikUser.touched.firstName &&
                  Boolean(formikUser.errors.firstName)
                }
                onChange={formikUser.handleChange}
                onBlur={formikUser.handleBlur}
                style={{ width: "100%" }}
                size="small"
                helperText={
                  formikUser.touched.firstName && formikUser.errors.firstName
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Last name"
                name="lastName"
                required
                InputLabelProps={{
                  shrink: true,
                }}
                value={formikUser.values.lastName}
                error={
                  formikUser.touched.lastName &&
                  Boolean(formikUser.errors.lastName)
                }
                onChange={formikUser.handleChange}
                onBlur={formikUser.handleBlur}
                helperText={
                  formikUser.touched.lastName && formikUser.errors.lastName
                }
                style={{ width: "100%" }}
                size="small"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Email"
                required
                name="email"
                InputLabelProps={{
                  shrink: true,
                }}
                value={formikUser.values.email}
                error={
                  formikUser.touched.email && Boolean(formikUser.errors.email)
                }
                helperText={formikUser.touched.email && formikUser.errors.email}
                onChange={formikUser.handleChange}
                onBlur={formikUser.handleBlur}
                style={{ width: "100%" }}
                size="small"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Phone number"
                InputLabelProps={{
                  shrink: true,
                }}
                name="phoneNumber"
                value={formikUser.values.phoneNumber}
                error={
                  formikUser.touched.phoneNumber &&
                  Boolean(formikUser.errors.phoneNumber)
                }
                helperText={
                  formikUser.touched.phoneNumber &&
                  formikUser.errors.phoneNumber
                }
                onChange={formikUser.handleChange}
                onBlur={formikUser.handleBlur}
                style={{ width: "100%" }}
                size="small"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Password"
                InputLabelProps={{
                  shrink: true,
                }}
                name="password"
                value={formikUser.values.password}
                error={
                  formikUser.touched.password &&
                  Boolean(formikUser.errors.password)
                }
                helperText={
                  formikUser.touched.password && formikUser.errors.password
                }
                type="password"
                onChange={formikUser.handleChange}
                onBlur={formikUser.handleBlur}
                style={{ width: "100%" }}
                size="small"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Confirm password"
                InputLabelProps={{
                  shrink: true,
                }}
                required
                type="password"
                style={{ width: "100%" }}
                size="small"
                name="confirmPassword"
                value={formikUser.values.confirmPassword}
                error={
                  formikUser.touched.confirmPassword &&
                  Boolean(formikUser.errors.confirmPassword)
                }
                helperText={
                  formikUser.touched.confirmPassword &&
                  formikUser.errors.confirmPassword
                }
                onChange={formikUser.handleChange}
                onBlur={formikUser.handleBlur}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                options={Countrys.map((o) => {
                  return { label: o.niceName, id: o.id };
                })}
                value={getSelectedValue(formikUser.values.countryId || 0)}
                size="small"
                sx={{ width: "100%" }}
                onChange={(o, val) => {
                  formikUser.setFieldValue("countryId", val?.id || 0);
                }}
                renderInput={(params) => (
                  <TextField
                    name="countryId"
                    {...params}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={formikUser.values.countryId}
                    error={
                      formikUser.touched.countryId &&
                      Boolean(formikUser.errors.countryId)
                    }
                    helperText={
                      formikUser.touched.countryId &&
                      formikUser.errors.countryId
                    }
                    onChange={formikUser.handleChange}
                    onBlur={formikUser.handleBlur}
                    label="Country"
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Address"
                name="address"
                InputLabelProps={{
                  shrink: true,
                }}
                value={formikUser.values.address}
                error={
                  formikUser.touched.address &&
                  Boolean(formikUser.errors.address)
                }
                helperText={
                  formikUser.touched.address && formikUser.errors.address
                }
                onChange={formikUser.handleChange}
                onBlur={formikUser.handleBlur}
                style={{ width: "100%" }}
                size="small"
              />
            </Grid>
            {GetRoles().includes(Role.sysadmin) && (
              <Grid item xs={12}>
                <Autocomplete
                  options={companys.map((o) => {
                    return { label: o.name, id: o.id };
                  })}
                  value={
                    companys
                      .filter((o) => o.id === formikUser.values.companyId)
                      .map((o) => {
                        return { label: o.name, id: o.id };
                      })[0]
                  }
                  size="small"
                  sx={{ width: "100%" }}
                  onChange={(o, val) => {
                    formikUser.setFieldValue("companyId", val?.id || 0);
                  }}
                  renderInput={(params) => (
                    <TextField
                      name="companyId"
                      {...params}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={formikUser.values.companyId}
                      error={
                        formikUser.touched.companyId &&
                        Boolean(formikUser.errors.companyId)
                      }
                      helperText={
                        formikUser.touched.companyId &&
                        formikUser.errors.companyId
                      }
                      onChange={formikUser.handleChange}
                      onBlur={formikUser.handleBlur}
                      label="Company"
                    />
                  )}
                />
              </Grid>
            )}
            <Grid item xs={6}>
              <label style={{ fontSize: 12 }}>Status</label>
              <Switch
                name="status"
                checked={formikUser.values.status === Api.RowStatus.Active}
                onChange={(e, val) => {
                  formikUser.setFieldValue(
                    "status",
                    val ? Api.RowStatus.Active : Api.RowStatus.Inactive
                  );
                }}
              />
            </Grid>
            {/* <Grid item xs={6}>
              <label style={{ fontSize: 12 }}>Two Factor Enabled</label>
              <Switch
                name="twoFactorEnabled"
                checked={formikUser.values.twoFactorEnabled}
                onChange={(e, val) => {
                  formikUser.setFieldValue("twoFactorEnabled", val);
                }}
              />
            </Grid> */}
            <Grid item xs={12}>
              <Stack spacing={2} direction="row" justifyContent={"flex-end"}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    onClose && onClose("Close");
                  }}
                >
                  Close
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    formikUser.handleSubmit();
                  }}
                >
                  Save
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
};
export default UserModal;
