import * as React from "react";
import { Outlet } from "react-router-dom";
import { Alert, Snackbar } from "@mui/material";
import { MsgState } from "./recoil/msgState";
import { useRecoilState } from "recoil";
import { ConfirmDialog } from "./dialog/confimDialog";
function LayoutBasic() {
  const [msgState, SetMsgState] = useRecoilState(MsgState);
  const handleSnackbarClose = () => {
    SetMsgState((cur) => {
      return { ...cur, Msg: "", show: false };
    });
  };

  return (
    <>
      <Snackbar
        open={msgState.show}
        autoHideDuration={10000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        style={{ width: "100%" }}
        onClose={handleSnackbarClose}
      >
        <Alert
          severity="error"
          onClose={handleSnackbarClose}
          sx={{ width: "100%" }}
        >
          {msgState.Msg}
        </Alert>
      </Snackbar>
      <Outlet></Outlet>
      <ConfirmDialog></ConfirmDialog>
    </>
  );
}

export default LayoutBasic;
